import Service from "src/service/ApiService";
import { API_URL } from "src/constants";

const service = new Service();

export const GetBaseMapSetting = async (
  pageIndex = null,
  pageSize = null,
  sortExpression = "",
  title = ""
) => {
  try {
    const params = new URLSearchParams();
    pageIndex && params.append("pageIndex", pageIndex);
    pageSize && params.append("pageSize", pageSize);
    sortExpression && params.append("sortExpression", sortExpression);
    title && params.append("title", title);
    const res = await service.get(API_URL.GetListBaseMapSetting, params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const GetBaseMapSettingLookup = () => {
  return service
    .get(API_URL.GetListBaseMapSetting)
    .then(res => {
      return res;
    })
    .catch(err => {
      throw err;
    });
};

export const CreateBaseMap = async body => {
  try {
    const res = await service.post(API_URL.CreateBaseMap, body);
    return res;
  } catch (err) {
    throw err;
  }
};

export const UpdateBaseMap = async body => {
  try {
    const res = await service.post(API_URL.UpdateBaseMap, body);
    return res;
  } catch (err) {
    throw err;
  }
};

export const DeleteBaseMap = async id => {
  try {
    const params = new URLSearchParams();
    params.append("Id", id);
    const res = await service.get(API_URL.DeleteBaseMap, params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const ValidateLayerType = async layerType => {
  try {
    const params = new URLSearchParams();
    params.append("layerType", layerType);
    const res = await service.get(API_URL.ValidateLayerType, params);
    return res;
  } catch (err) {
    throw err;
  }
};
