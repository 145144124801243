import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import LoadingScreen from "src/components/LoadingScreen";
import AuthGuard from "src/components/AuthGuard";
import GuestGuard from "src/components/GuestGuard";
import MainLayout from "src/layouts/MainLayout";
import { ROUTER_NAME } from "src/constants";

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: ROUTER_NAME.NOT_FOUND,
    component: lazy(() => import("src/views/Errors/NotFoundView"))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: ROUTER_NAME.LOGIN,
    component: lazy(() => import("src/views/Login"))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: ROUTER_NAME.REGISTER,
    component: lazy(() => import("src/views/Register"))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: ROUTER_NAME.FORGOT_PASSWORD,
    component: lazy(() => import("src/views/ForgotPassword"))
  },
  {
    exact: true,
    path: ROUTER_NAME.RESET_PASSWORD,
    component: lazy(() => import("src/views/ResetPassword"))
  },
  {
    path: ROUTER_NAME.ALL,
    guard: AuthGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: ROUTER_NAME.DEFAULT,
        component: lazy(() => import("src/views/MapLayer"))
      },
      {
        exact: true,
        path: ROUTER_NAME.TOOL,
        component: lazy(() => import("src/views/ToolManagement"))
      },
      {
        exact: true,
        path: ROUTER_NAME.TOOL_TYPE,
        component: lazy(() => import("src/views/ToolTypeManagement"))
      },
      {
        exact: true,
        path: ROUTER_NAME.MAP_LAYER,
        component: lazy(() => import("src/views/MapLayer"))
      },
      {
        exact: true,
        path: ROUTER_NAME.MAP_DOCUMENT,
        component: lazy(() => import("src/views/MapManagement"))
      },
      {
        exact: true,
        path: ROUTER_NAME.DOCUMENT,
        component: lazy(() => import("src/views/DocumentView"))
      },
      {
        exact: true,
        path: ROUTER_NAME.USER_MANAGEMENT,
        component: lazy(() => import("src/views/UserManagement"))
      },
      {
        exact: true,
        path: ROUTER_NAME.GUIDE,
        component: lazy(() => import("src/views/Guide"))
      },
      {
        exact: true,
        path: ROUTER_NAME.TERM,
        component: lazy(() => import("src/views/TermView"))
      },
      {
        exact: true,
        path: ROUTER_NAME.FEEDBACK,
        component: lazy(() => import("src/views/Feedback"))
      },
      {
        exact: true,
        path: ROUTER_NAME.BASE_MAP_SETTING,
        component: lazy(() => import("src/views/BaseMapSettingView"))
      },
      {
        exact: true,
        path: ROUTER_NAME.NORMAL_INFO,
        component: lazy(() => import("src/views/NormalInfoView"))
      },
      {
        exact: true,
        path: ROUTER_NAME.FEEDBACK_INFO,
        component: lazy(() => import("src/views/FeedbackInfoView"))
      },
      {
        component: () => <Redirect to={ROUTER_NAME.NOT_FOUND} />
      }
    ]
  }
];

export default routes;
