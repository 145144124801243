import { ACTIONS_TYPES } from "src/constants";

const initialState = {
  mapLayers: null
};

export const mapLayersReducers = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS_TYPES.SET_MAP_LAYER_DATA:
      return {
        ...state,
        mapLayers: action.payload.mapLayers
      };
    default:
      return state;
  }
};
