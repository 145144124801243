import { ACTIONS_TYPES } from "src/constants";

const DEFAULT_STEP = 1;
const TOTAL_STEP = 3;

const initialState = {
  step: DEFAULT_STEP,
  totalStep: TOTAL_STEP,
  createLayerSetting: null
};

export const AddLayerSettingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS_TYPES.SET_DEFAULT_STEP:
      return {
        ...state,
        step: action.payload.step
      };
    case ACTIONS_TYPES.SET_TOTAL_STEP:
      return {
        ...state,
        step: action.payload.totalStep
      };
    case ACTIONS_TYPES.NEXT_STEP:
      const step = action.payload.step || state.step + 1;
      const nextStep = step > state.totalStep ? state.totalStep : step;
      return {
        ...state,
        step: nextStep
      };
    case ACTIONS_TYPES.PREV_STEP:
      const prevStep = state.step > 1 ? state.step - 1 : DEFAULT_STEP;
      return {
        ...state,
        step: prevStep
      };
    case ACTIONS_TYPES.SET_CREATE_LAYER_SETTING:
      return {
        ...state,
        createLayerSetting: action.payload
      };
    default:
      return state;
  }
};

export const setDefaultStep = step => ({
  type: ACTIONS_TYPES.SET_DEFAULT_STEP,
  payload: step
});

export const setTotalStep = totalStep => ({
  type: ACTIONS_TYPES.SET_TOTAL_STEP,
  payload: totalStep
});
export const nextStep = step => ({
  type: ACTIONS_TYPES.NEXT_STEP,
  payload: { step }
});

export const prevStep = () => ({
  type: ACTIONS_TYPES.PREV_STEP
});

export const setCreateSettingLayerData = data => ({
  type: ACTIONS_TYPES.SET_CREATE_LAYER_SETTING,
  payload: data
});
