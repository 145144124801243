import React, { createContext, useEffect, useReducer } from "react";

import * as AuthService from "src/redux/store/auth/auth.store";

import SplashScreen from "src/components/SplashScreen";
import { LOCAL_STORAGE_KEY } from "src/constants";
import { GetDataFromStorage, ClearStorage, SetDataToStorage } from "src/utils";

const ACTION_TYPE = {
  INITIALIZE: "INITIALIZE",
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT"
};

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.INITIALIZE: {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user
      };
    }
    case ACTION_TYPE.LOGIN: {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case ACTION_TYPE.LOGOUT: {
      ClearStorage();
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    default: {
      return state;
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  login: () => Promise.resolve(),
  logout: () => {}
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const initialAuth = async () => {
    const _token = GetDataFromStorage(LOCAL_STORAGE_KEY.TOKEN);
    let user = null;

    if (_token) {
      try {
        const res = await AuthService.GetUserAccountDetail();
        user = res?.content || null;
      } catch (error) {}
    }

    dispatch({
      type: ACTION_TYPE.INITIALIZE,
      payload: {
        isAuthenticated: !!_token,
        user: user
      }
    });
  };

  const login = async data => {
    try {
      const resLogin = await AuthService.Login(data);
      if (resLogin?.content?.token) {
        SetDataToStorage(LOCAL_STORAGE_KEY.TOKEN, resLogin.content.token);
        const resUseInfo = await AuthService.GetUserAccountDetail();

        if (resUseInfo?.content) {
          dispatch({
            type: ACTION_TYPE.LOGIN,
            payload: {
              user: resUseInfo.content
            }
          });
          return resLogin;
        }

        throw resUseInfo;
      }

      throw resLogin;
    } catch (error) {
      throw error;
    }
  };

  const logout = () => {
    dispatch({ type: ACTION_TYPE.LOGOUT });
  };

  useEffect(() => {
    initialAuth();
  }, []);

  if (!state.isInitialized) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
