import * as actionType from "./ActionType";

const DEFAULT_STEP = 1;
const TOTAL_STEP = 3;

const initialState = {
  step: DEFAULT_STEP,
  totalStep: TOTAL_STEP,
  layers: [],

  initLayerCategoryData: [
    {
      id: -new Date().getTime(),
      folder_label: "groupLayersName",
      folder_name: "groupLayersName",
      layer_settings: [],
      level: 0
    }
  ],
  createSettingLayerData: null,
  editSettingLayerData: null,
  currentLayerCategorySetting: null,
  index: {}
};

export const SettingLayerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_DEFAULT_STEP:
      return {
        ...state,
        step: action.payload.step
      };
    case actionType.SET_TOTAL_STEP:
      return {
        ...state,
        step: action.payload.totalStep
      };
    case actionType.NEXT_STEP:
      const step = action.payload.step || state.step + 1;
      const nextStep = step > state.totalStep ? state.totalStep : step;
      return {
        ...state,
        step: nextStep
      };
    case actionType.PREV_STEP:
      const prevStep = state.step > 1 ? state.step - 1 : DEFAULT_STEP;
      return {
        ...state,
        step: prevStep
      };
    case actionType.SET_LAYERS:
      return {
        ...state,
        layers: action.payload.layers
      };
    case actionType.SET_INIT_LAYER_CATEGORY_DATA:
      return {
        ...state,
        initLayerCategoryData: action.payload
      };
    case actionType.SET_CREATE_SETTING_LAYER_DATA:
      return {
        ...state,
        createSettingLayerData: action.payload
      };
    case actionType.SET_CURRENT_LAYER_CATEGORY_SETTING:
      return {
        ...state,
        currentLayerCategorySetting: action.payload
      };
    case actionType.SET_INDEX_LAYER_GROUP:
      return {
        ...state,
        index: {
          indexGroup: action.payload.indexGroup,
          indexLayer: action.payload.indexLayer
        }
      };
    default:
      return state;
  }
};

export const setDefaultStep = step => ({
  type: actionType.SET_DEFAULT_STEP,
  payload: step
});

export const setTotalStep = totalStep => ({
  type: actionType.SET_TOTAL_STEP,
  payload: totalStep
});
export const nextStep = step => ({
  type: actionType.NEXT_STEP,
  payload: { step }
});

export const prevStep = () => ({
  type: actionType.PREV_STEP
});

export const setLayers = layers => ({
  type: actionType.SET_LAYERS,
  payload: {
    layers
  }
});

export const setInitLayerCategoryData = data => ({
  type: actionType.SET_INIT_LAYER_CATEGORY_DATA,
  payload: data
});

export const setCreateSettingLayerData = data => ({
  type: actionType.SET_CREATE_SETTING_LAYER_DATA,
  payload: data
});

export const setCurrentCategorySetting = data => ({
  type: actionType.SET_CURRENT_LAYER_CATEGORY_SETTING,
  payload: data
});
export const setIndexGroup = (indexGroup = -1, indexLayer = -1) => ({
  type: actionType.SET_INDEX_LAYER_GROUP,
  payload: { indexGroup: indexGroup, indexLayer: indexLayer }
});
