import React from "react";
import { Box, Drawer, List, makeStyles, Divider } from "@material-ui/core";
import NavItem from "./NavItem";
import { ROUTER_NAME } from "src/constants";

//--- Icon
import HomeIcon from "@material-ui/icons/Home";
import BuildIcon from "@material-ui/icons/Build";
import LayersIcon from "@material-ui/icons/Layers";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import AssignmentIcon from "@material-ui/icons/Assignment";
import GavelIcon from "@material-ui/icons/Gavel";
import FeedbackIcon from "@material-ui/icons/Feedback";
import MapIcon from "@material-ui/icons/Map";

const items = [
  // {
  //   href: ROUTER_NAME.MAP_LAYER,
  //   icon: HomeIcon,
  //   title: "Trang chủ",
  //   showBorder: true
  // },
  {
    href: ROUTER_NAME.TOOL,
    icon: BuildIcon,
    title: "Công cụ",
    showBorder: true
  },
  {
    href: ROUTER_NAME.TOOL_TYPE,
    icon: FiberManualRecordIcon,
    title: "Loại công cụ",
    showBorder: true
  },
  {
    href: ROUTER_NAME.MAP_LAYER,
    icon: LayersIcon,
    title: "Lớp bản đồ",
    showBorder: true
  },
  {
    href: ROUTER_NAME.BASE_MAP_SETTING,
    icon: MapIcon,
    title: "Quản lý Bản đồ nền",
    showBorder: true
  },
  {
    href: ROUTER_NAME.MAP_DOCUMENT,
    icon: NoteAddIcon,
    title: "Tài liệu Bản đồ",
    showBorder: true
  },
  {
    href: ROUTER_NAME.DOCUMENT,
    icon: NoteAddIcon,
    title: "Tài liệu Văn bản",
    showBorder: true
  },
  {
    href: ROUTER_NAME.USER_MANAGEMENT,
    icon: AccountCircleIcon,
    title: "Quản lý người dùng",
    showBorder: true
  },
  {
    href: ROUTER_NAME.TERM,
    icon: GavelIcon,
    title: "Điều khoản",
    showBorder: true
  },
  {
    href: ROUTER_NAME.GUIDE,
    icon: AssignmentIcon,
    title: "Hướng dẫn",
    showBorder: true
  },
  {
    href: ROUTER_NAME.FEEDBACK,
    icon: FeedbackIcon,
    title: "Phản hồi",
    showBorder: true
  },
  {
    title: "Quản lý khác",
    icon: FeedbackIcon,
    subLinks: [
      {
        href: ROUTER_NAME.NORMAL_INFO,
        icon: AssignmentIcon,
        title: "Thay đổi logo",
        showBorder: true
      },
      {
        href: ROUTER_NAME.FEEDBACK_INFO,
        icon: AssignmentIcon,
        title: "Thay đổi góp ý",
        showBorder: true
      }
    ]
  }
];

const useStyles = makeStyles(() => ({
  desktopDrawer: {
    width: 240,
    top: 70,
    height: "calc(100% - 64px)",
    backgroundColor: "#354ea9"
  }
}));

const NavBar = () => {
  const classes = useStyles();

  return (
    <Drawer
      open
      anchor="left"
      classes={{ paper: classes.desktopDrawer }}
      variant="persistent"
    >
      <Box height="100%" display="flex" flexDirection="column">
        <Box p={2}>
          <List>
            <Divider style={{ backgroundColor: "rgb(255 255 255 / 12%)" }} />
            {items.map(item => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
                subLinks={item?.subLinks || null}
                {...item}
              />
            ))}
          </List>
        </Box>
        <Box flexGrow={1} />
      </Box>
    </Drawer>
  );
};

export default NavBar;
