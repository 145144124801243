import Service from "src/service/ApiService";
import { API_URL } from "src/constants";

const service = new Service();

export const GetWebCommon = async data => {
  try {
    const res = await service.get(API_URL.GetWebCommonViewData);
    return res;
  } catch (err) {
    throw err;
  }
};

export const UpdateWebCommon = async data => {
  try {
    const formData = new FormData();
    formData.append("WebLogo", data.webLogo);
    formData.append("WebTitle", data.webTitle);
    formData.append("WebSubTitle", data.webSubTitle);
    data.file && formData.append("file", data.file);
    const res = await service.post(API_URL.UpdateWebCommon, formData);
    return res;
  } catch (err) {
    throw err;
  }
};
