export const SET_DEFAULT_STEP = "SET_DEFAULT_STEP";
export const SET_TOTAL_STEP = "SET_TOTAL_STEP";
export const NEXT_STEP = "NEXT_STEP";
export const PREV_STEP = "PREV_STEP";

export const SET_LAYERS = "SET_LAYERS";

export const SET_INIT_LAYER_CATEGORY_DATA = "SET_INIT_LAYER_CATEGORY_DATA";
export const SET_CREATE_SETTING_LAYER_DATA = "SET_CREATE_SETTING_LAYER_DATA";
export const SET_EDIT_SETTING_LAYER_DATA = "SET_CREATE_SETTING_LAYER_DATA";
export const SET_CURRENT_LAYER_CATEGORY_SETTING ="SET_CURRENT_LAYER_CATEGORY_SETTING";
export const SET_INDEX_LAYER_GROUP ="SET_INDEX_LAYER_GROUP";
