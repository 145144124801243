export const API_URL = {
  Login: "api/Account/Login",
  Register: "api/Account/Register",
  ForgotPassword: "api/Account/ForgotPassword",
  ResetPasswordAccount: "api/Account/ResetPassword",
  GetUserAccountDetail: "api/Account/GetUserAccountDetail",

  GetHomePageMapViewData: "api/cms/AdminHomePage/GetHomePageMapViewData",
  UpdateLayer: "api/cms/AdminHomePage/UpdateLayerHaveImage",
  GetPgSchema: "api/admin/PgTable/get-pg-schema",

  GetListMapTool: "api/cms/AdminHomePage/GetListMapTool",
  GetLookupMapTool: "api/cms/AdminHomePage/GetLookupMapTool",
  GetDetailMapTool: "api/cms/AdminHomePage/GetDetailMapTool",
  UpdateMapTool: "api/cms/AdminHomePage/UpdateMapTool",

  GetLookupCategory: "api/cms/AdminHomePage/GetLookupCategory",
  CreateToolCategory: "api/cms/AdminHomePage/CreateMapToolCategory",
  UpdateCategory: "api/cms/AdminHomePage/UpdateCategory",
  DeleteCategory: "api/cms/AdminHomePage/DeleteMapToolCategory",

  GetUserManagement: "api/admin/User/GetUserManagement",
  GetUserDetail: "api/admin/User/GetUserDetail",
  CreateUser: "api/admin/User/CreateUser",
  UpdateUser: "api/admin/User/UpdateUser",
  ActiveUser: "api/admin/User/ActiveUser",
  DeActiveUser: "api/admin/User/DeActiveUser",
  ResetPassword: "api/admin/User/ResetPassword",
  DeleteUser: "api/admin/User/DeleteUser",

  GetTutorials: "api/cms/AdminIntroduce/GetTutorials",
  GetTerms: "api/cms/AdminIntroduce/GetTerms",
  UpdateTutorials: "api/cms/AdminIntroduce/UpdateTutorials",
  UpdateTerms: "api/cms/AdminIntroduce/UpdateTerms",

  GetMapPlanningByFolderId: "api/cms/AdminMapPlanning/GetMapPlanningByFolderId",
  GetMapPlanningFolders: "api/cms/AdminMapPlanning/GetMapPlanningFolders",
  CreateMapPlanningCategory:
    "api/cms/AdminMapPlanning/CreateMapPlanningCategory",
  DeleteMapPlanningCategory:
    "api/cms/AdminMapPlanning/DeleteMapPlanningCategory",
  CreateMapPlanning: "api/cms/AdminMapPlanning/CreateMapPlanning",
  UpdateMapPlanning: "api/cms/AdminMapPlanning/UpdateMapPlanning",
  DeleteMapPlanning: "api/cms/AdminMapPlanning/DeleteMapPlanning",

  GetMapDocumentByFolderId: "api/cms/AdminMapDocument/GetMapDocumentByFolderId",
  GetMapDocumentFolders: "api/cms/AdminMapDocument/GetMapDocumentFolders",
  CreateMapDocument: "api/cms/AdminMapDocument/CreateMapDocument",
  UpdateMapDocument: "api/cms/AdminMapDocument/UpdateMapDocument",
  DeleteMapDocument: "api/cms/AdminMapDocument/DeleteMapDocument",
  CreateMapDocumentCategory:
    "api/cms/AdminMapDocument/CreateMapDocumentCategory",
  DeleteMapDocumentCategory:
    "api/cms/AdminMapDocument/DeleteMapDocumentCategory",

  GetListFeedbacks: "/api/cms/AdminFeedback/GetListFeedbacks",

  CreateBaseMap: "api/cms/AdminBaseMapSetting/Create",
  UpdateBaseMap: "api/cms/AdminBaseMapSetting/Update",
  DeleteBaseMap: "api/cms/AdminBaseMapSetting/Delete",
  GetListBaseMapSetting: "api/cms/AdminBaseMapSetting/GetListBaseMapSetting",
  ValidateLayerType: "api/cms/AdminBaseMapSetting/ValidateLayerType",

  GetWebCommonViewData: "api/cms/AdminHomePage/GetWebCommonViewData",
  UpdateWebCommon: "api/cms/AdminHomePage/UpdateWebCommon",
  GetFeedbackContent: "api/cms/AdminFeedback/GetFeedbackContent",
  UpdateFeedbackContent: "api/cms/AdminFeedback/UpdateFeedbackContent"
};

export const API_URL_ROOT = "https://api.cargis.vn/";
