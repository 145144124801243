import Service from "src/service/ApiService";
import { API_URL } from "src/constants";

const service = new Service();

export const GetTutorials = () => {
  return service
    .get(API_URL.GetTutorials)
    .then(res => {
      return res;
    })
    .catch(err => {
      throw err;
    });
};

export const UpdateTutorials = body => {
  return service
    .post(API_URL.UpdateTutorials, body)
    .then(res => {
      return res;
    })
    .catch(err => {
      throw err;
    });
};
