import Service from "src/service/ApiService";
import { API_URL } from "src/constants";

const service = new Service();

export const GetMapPlanningByFolderId = async (
  folderId,
  sortExpression,
  name
) => {
  try {
    const params = new URLSearchParams();
    folderId && params.append("folderId", folderId);
    sortExpression && params.append("sortExpression", sortExpression);
    name && params.append("name", name);
    const res = await service.get(API_URL.GetMapPlanningByFolderId, params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const GetMapPlanningFolders = async () => {
  try {
    const res = await service.get(API_URL.GetMapPlanningFolders);
    return res;
  } catch (err) {
    throw err;
  }
};
export const CreateMapPlanningCategory = async data => {
  try {
    const res = await service.post(API_URL.CreateMapPlanningCategory, data);
    return res;
  } catch (err) {
    throw err;
  }
};
export const UpdateMapPlanningCategory = async data => {
  try {
    const res = await service.post(API_URL.UpdateCategory, data);
    return res;
  } catch (err) {
    throw err;
  }
};
export const DeleteMapPlanningCategory = async id => {
  try {
    const params = new URLSearchParams();
    params.append("Id", id);
    const res = await service.postParams(
      API_URL.DeleteMapPlanningCategory,
      params
    );
    return res;
  } catch (err) {
    throw err;
  }
};
export const DeleteMapPlanning = async data => {
  try {
    const params = new URLSearchParams();
    (data || []).map(({ id }) => {
      params.append("Ids", id);
    });

    const res = await service.postParams(API_URL.DeleteMapPlanning, params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const CreateMapPlanning = async data => {
  const formData = new FormData();
  formData.append("Name", data.name);
  formData.append("YearEstablish", data?.yearEstablish);
  formData.append("DateUpload", data?.dateUpload || "");
  formData.append("FilePart", data?.filePart || "");
  formData.append("FileExtension", data?.fileExtension || "");
  formData.append("Thumbnail", data?.thumbnail || "");
  formData.append("UnitEstablish", data.unitEstablish || "");
  formData.append("Scale", data?.scale || "");
  formData.append("CategoryId", data?.categoryId);
  formData.append("file", data?.file);
  formData.append("thumbnail", data?.thumbnail);
  try {
    const res = await service.post(API_URL.CreateMapPlanning, formData);
    return res;
  } catch (err) {
    throw err;
  }
};
export const UpdateMapPlanning = async data => {
  try {
    const formData = new FormData();
    formData.append("Id", data.id);
    formData.append("Name", data.name);
    formData.append("YearEstablish", data?.yearEstablish);
    formData.append("DateUpload", data?.dateUpload || "");
    formData.append("FilePart", data?.filePart || "");
    formData.append("FileExtension", data?.fileExtension || "");
    formData.append("Thumbnail", data?.thumbnail || "");
    formData.append("UnitEstablish", data.unitEstablish || "");
    formData.append("Scale", data?.scale || "");
    formData.append("CategoryId", data?.categoryId);
    data?.file && formData.append("file", data.file);
    formData.append("thumbnail", data?.thumbnailTemp || "");
    const res = await service.post(API_URL.UpdateMapPlanning, formData);
    return res;
  } catch (err) {
    throw err;
  }
};
