export const ROUTER_NAME = {
  ALL: "*",
  DEFAULT: "/",
  NOT_FOUND: "/404",
  LOGIN: "/dang-nhap",
  REGISTER: "/dang-ki",
  TOOL: "/cong-cu",
  TOOL_TYPE: "/loai-cong-cu",
  MAP_LAYER: "/lop-ban-do",
  MAP_DOCUMENT: "/tai-lieu-ban-do",
  FORGOT_PASSWORD: "/quen-mat-khau",
  RESET_PASSWORD: "/thay-mat-khau",
  USER_MANAGEMENT: "/quan-ly-nguoi-dung",
  GUIDE: "/huong-dan",
  FEEDBACK: "/phan-hoi",
  TERM:'/dieu-khoan',
  DOCUMENT:'/van-ban',
  BASE_MAP_SETTING:'/ban-do-nen',
  NORMAL_INFO:'/thong-tin-chung',
  FEEDBACK_INFO:'/thong-tin-gop-y'
};
