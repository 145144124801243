import { combineReducers } from "@reduxjs/toolkit";
import { reducer as formReducer } from "redux-form";

import {
  SettingLayerReducer,
  AppReducer,
  mapLayersReducers,
  AddLayerSettingReducer,
  HomeReducer
} from "./store";

const rootReducer = combineReducers({
  form: formReducer,
  app: AppReducer,
  mapLayers: mapLayersReducers,
  addLayer: AddLayerSettingReducer,
  settingLayer: SettingLayerReducer,
  home: HomeReducer
});

export default rootReducer;
